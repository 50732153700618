import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";
import { CognitoUser } from "amazon-cognito-identity-js";

import Layout from "../components/layout";
import UserPool from "../components/auth/UserPool";

import {
  AuthenticationState,
  AuthenticationDispatch,
} from "../context/AuthenticationContext";

export default function LoginPage() {
  const authenticationState = useContext(AuthenticationState) || "";
  const authenticationDispatch = useContext(AuthenticationDispatch) || "";

  useEffect(() => {
    const user = new CognitoUser({
      Username: authenticationState.username,
      Pool: UserPool,
    });

    // Sign user out
    user.signOut();

    // Remove token from context
    authenticationDispatch({
      type: "REMOVE_TOKEN",
    });

    // Clear the localstorage
    localStorage.removeItem('objectId');
    localStorage.removeItem('objectMetadata');

    // Redirect user to the homepage
    navigate("/login");
  }, []);

  return (
    <Layout>
      <h2 className="text-center">Logging you out of the system.</h2>
    </Layout>
  );
}
